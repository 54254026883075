import { config } from '@/config';

export const LAYOUT_C1 = [
  { i: 'prospects', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'clients', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'allContacts', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'clientsBirthdays', x: 0, y: 2, w: 3, h: 4 },
  { i: 'prospectsBirthdays', x: 3, y: 2, w: 3, h: 4 },
  { i: 'upcomingEvents', x: 6, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
  { i: 'pastDueCalendarEvents', x: 6, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
  { i: 'recentlySavedContacts', x: 6, y: 0, w: 4, h: 2, minW: 2, minH: 2 },
  { i: 'clientAepOpps', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'dataCaptureAepOpps', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'lifeCrossSell', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'annuityCrossSell', x: 6, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'medicareCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 }
];

export const LAYOUT_ARIS = [
  { i: 'allLeads', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'newsLetters', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'olderPolicies', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'policyAnniversary', x: 0, y: 6, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
  { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
  { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'upcomingEvents', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
  { i: 'upcomingTasks', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
  { i: 'upcomingFollowUps', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
  { i: 'notesForUsers', x: 0, y: 10, w: 12, h: 2, minW: 10, minH: 2 },
  { i: 'clientAepOpps', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'dataCaptureAepOpps', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'lifeCrossSell', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'annuityCrossSell', x: 6, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
  { i: 'medicareCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 }
];

export const LAYOUT = (() => {
  if (config().CAREINADVANCE) {
    return [
      { i: 'hotProspects', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'newsLetters', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'aftercareClients', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
      { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
      { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'upcomingEvents', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingTasks', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingFollowUps', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 6, w: 12, h: 2, minW: 10, minH: 2 }
    ];
  }
  if (config().BROKERAGEASB) {
    return [
      { i: 'newContacts', x: 0, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'progress', x: 2, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'closed', x: 4, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'clients', x: 6, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'allContacts', x: 8, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 6, w: 12, h: 2, minW: 10, minH: 2 }
    ];
  }
  if (config().MYASBFINANCIAL) {
    return [
      { i: 'summary', x: 0, y: 0, w: 3, h: 6, minW: 3, minH: 6 },
      { i: 'pending', x: 3, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'newsLetters', x: 5, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clients', x: 3, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'policyAnniversary', x: 3, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsBirthdays', x: 0, y: 7, w: 3, h: 4 },
      { i: 'upcomingEvents', x: 7, y: 2, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingTasks', x: 7, y: 2, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingFollowUps', x: 7, y: 2, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'clientAepOpps', x: 7, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'dataCaptureAepOpps', x: 9, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'lifeCrossSell', x: 5, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'annuityCrossSell', x: 5, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'medicareCrossSell', x: 5, y: 6, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWAnnuity', x: 7, y: 8, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWLife', x: 9, y: 10, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 6, w: 12, h: 2, minW: 10, minH: 2 }
    ];
  }
  if (config().ASBAGENTMN) {
    return [
      { i: 'allLeads', x: 0, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'newsLetters', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'policyAnniversary', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'spouseT65', x: 0, y: 7, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
      { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
      { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'upcomingEvents', x: 8, y: 4, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingTasks', x: 8, y: 6, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingFollowUps', x: 8, y: 8, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'clientAepOpps', x: 8, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'dataCaptureAepOpps', x: 10, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'lifeCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'annuityCrossSell', x: 10, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'medicareCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWAnnuity', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWLife', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 10, w: 12, h: 2, minW: 10, minH: 2 }
    ];
  }
  if (config().ASB_SYSTEM) {
    return [
      { i: 'allLeads', x: 0, y: 0, w: 2, h: 3, minW: 2, minH: 2 },
      { i: 'newsLetters', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'policyAnniversary', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
      { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
      { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'upcomingEvents', x: 8, y: 4, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingTasks', x: 8, y: 6, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingFollowUps', x: 8, y: 8, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'clientAepOpps', x: 8, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'dataCaptureAepOpps', x: 10, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'lifeCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'annuityCrossSell', x: 10, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'medicareCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWAnnuity', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsWLife', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 10, w: 12, h: 2, minW: 10, minH: 2 }
    ];
  }
  if (config().AEGLEADS) {
    return [
      { i: 'allLeads', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'newsLetters', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'policyAnniversary', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'partDClients', x: 0, y: 6, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
      { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
      { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'upcomingEvents', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingTasks', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'upcomingFollowUps', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
      { i: 'notesForUsers', x: 0, y: 10, w: 12, h: 2, minW: 10, minH: 2 },
      { i: 'clientAepOpps', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'dataCaptureAepOpps', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'lifeCrossSell', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'annuityCrossSell', x: 6, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
      { i: 'medicareCrossSell', x: 8, y: 2, w: 2, h: 2, minW: 2, minH: 2 }
    ];
  }
  return [
    { i: 'allLeads', x: 0, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'newsLetters', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'policyAnniversary', x: 0, y: 4, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'prospects', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'clientsBirthdays', x: 2, y: 2, w: 3, h: 4 },
    { i: 'prospectsBirthdays', x: 5, y: 2, w: 3, h: 4 },
    { i: 'clients', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'allContacts', x: 6, y: 0, w: 2, h: 2, minW: 2, minH: 2 },
    { i: 'upcomingEvents', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
    { i: 'upcomingTasks', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
    { i: 'upcomingFollowUps', x: 8, y: 0, w: 4, h: 2, minW: 4, minH: 2 },
    { i: 'notesForUsers', x: 0, y: 10, w: 12, h: 2, minW: 10, minH: 2 }
  ];
})();
