import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import styles from './ActiveCards.module.scss';

const ActiveCards = ({ onSelect, onSave, activeFields, onReset, currentUser, config }) => {
  const {
    allLeads,
    hotProspects,
    prospects,
    progress,
    newContacts,
    closed,
    clients,
    allContacts,
    upcomingEvents,
    newsLetters,
    clientsBirthdays,
    recentlySavedContacts,
    prospectsBirthdays,
    upcomingTasks,
    policyAnniversary,
    olderPolicies,
    upcomingFollowUps,
    pastDueCalendarEvents,
    aftercareClients,
    summary,
    pending,
    dataCaptureAepOpps,
    clientAepOpps,
    lifeCrossSell,
    spouseT65,
    annuityCrossSell,
    clientsWAnnuity,
    clientsWLife,
    medicareCrossSell,
    partDClients
  } = activeFields;

  return (
    <div className={styles.activeCards}>
      <div className={styles.fieldsWrapper}>
        {!currentUser.c_1 && !config().BROKERAGEASB && !config().CAREINADVANCE && !config().MYASBFINANCIAL && (
          <div className={styles.field}>
            <input
              id="allLeads"
              type="checkbox"
              name="allLeads"
              className="is-checkradio is-large"
              onChange={() => onSelect('allLeads')}
              checked={allLeads}
            />
            <label htmlFor="allLeads">
              <p>ALL LEADS</p>
            </label>
          </div>
        )}
        {config().CAREINADVANCE && (
          <div className={styles.field}>
            <input
              id="hotProspects"
              type="checkbox"
              name="hotProspects"
              className="is-checkradio is-large"
              onChange={() => onSelect('hotProspects')}
              checked={hotProspects}
            />
            <label htmlFor="hotProspects">
              <p>HOT PROSPECTS</p>
            </label>
          </div>
        )}
        {((config().ASB_SYSTEM && !config().BROKERAGEASB) || config().AEGLEADS) && (
          <>
            <div className={styles.field}>
              <input
                id="clientAepOpps"
                type="checkbox"
                name="clientAepOpps"
                className="is-checkradio is-large"
                onChange={() => onSelect('clientAepOpps')}
                checked={clientAepOpps}
              />
              <label htmlFor="clientAepOpps">
                <p>CLIENT AEP OPPS</p>
              </label>
            </div>

            <div className={styles.field}>
              <input
                id="dataCaptureAepOpps"
                type="checkbox"
                name="dataCaptureAepOpps"
                className="is-checkradio is-large"
                onChange={() => onSelect('dataCaptureAepOpps')}
                checked={dataCaptureAepOpps}
              />
              <label htmlFor="dataCaptureAepOpps">
                <p>DATA CAPTURE AEP OPPS</p>
              </label>
            </div>
          </>
        )}
        {((config().ASB_SYSTEM && !config().BROKERAGEASB) || config().AEGLEADS) && (
          <>
            <div className={styles.field}>
              <input
                id="lifeCrossSell"
                type="checkbox"
                name="lifeCrossSell"
                className="is-checkradio is-large"
                onChange={() => onSelect('lifeCrossSell')}
                checked={lifeCrossSell}
              />
              <label htmlFor="lifeCrossSell">
                <p>LIFE CROSS SELL</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="annuityCrossSell"
                type="checkbox"
                name="annuityCrossSell"
                className="is-checkradio is-large"
                onChange={() => onSelect('annuityCrossSell')}
                checked={annuityCrossSell}
              />
              <label htmlFor="annuityCrossSell">
                <p>ANNUITY CROSS SELL</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="medicareCrossSell"
                type="checkbox"
                name="medicareCrossSell"
                className="is-checkradio is-large"
                onChange={() => onSelect('medicareCrossSell')}
                checked={medicareCrossSell}
              />
              <label htmlFor="medicareCrossSell">
                <p>MEDICARE CROSS SELL</p>
              </label>
            </div>
          </>
        )}
        {config().ASB_SYSTEM && !config().BROKERAGEASB && (
          <>
            <div className={styles.field}>
              <input
                id="clientsWAnnuity"
                type="checkbox"
                name="clientsWAnnuity"
                className="is-checkradio is-large"
                onChange={() => onSelect('clientsWAnnuity')}
                checked={clientsWAnnuity}
              />
              <label htmlFor="clientsWAnnuity">
                <p>CLIENT'S W/ ANNUITY</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="clientsWLife"
                type="checkbox"
                name="clientsWLife"
                className="is-checkradio is-large"
                onChange={() => onSelect('clientsWLife')}
                checked={clientsWLife}
              />
              <label htmlFor="clientsWLife">
                <p>CLIENT'S W/ LIFE</p>
              </label>
            </div>
          </>
        )}
        {config().BROKERAGEASB && (
          <>
            <div className={styles.field}>
              <input
                id="progress"
                type="checkbox"
                name="progress"
                className="is-checkradio is-large"
                onChange={() => onSelect('progress')}
                checked={progress}
              />
              <label htmlFor="progress">
                <p>PROGRESS</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="newContacts"
                type="checkbox"
                name="newContacts"
                className="is-checkradio is-large"
                onChange={() => onSelect('newContacts')}
                checked={newContacts}
              />
              <label htmlFor="newContacts">
                <p>NEW</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="closed"
                type="checkbox"
                name="closed"
                className="is-checkradio is-large"
                onChange={() => onSelect('closed')}
                checked={closed}
              />
              <label htmlFor="closed">
                <p>CLOSED</p>
              </label>
            </div>
          </>
        )}
        {!currentUser.c_1 && !config().BROKERAGEASB && (
          <div className={styles.field}>
            <input
              id="newsLetters"
              type="checkbox"
              name="newsLetters"
              className="is-checkradio is-large"
              onChange={() => onSelect('newsLetters')}
              checked={newsLetters}
            />
            <label htmlFor="newsLetters">
              <p>NEWSLETTERS</p>
            </label>
          </div>
        )}
        {currentUser.aris && (
          <div className={styles.field}>
            <input
              id="olderPolicies"
              type="checkbox"
              name="olderPolicies"
              className="is-checkradio is-large"
              onChange={() => onSelect('olderPolicies')}
              checked={olderPolicies}
            />
            <label htmlFor="olderPolicies">
              <p>NEW CONTACTS FOR OLDER POLICIES</p>
            </label>
          </div>
        )}
        {!currentUser.c_1 && !config().BROKERAGEASB && !config().CAREINADVANCE && (
          <div className={styles.field}>
            <input
              id="policyAnniversary"
              type="checkbox"
              name="policyAnniversary"
              className="is-checkradio is-large"
              onChange={() => onSelect('policyAnniversary')}
              checked={policyAnniversary}
            />
            <label htmlFor="policyAnniversary">
              <p>POLICY ANNIVERSARY</p>
            </label>
          </div>
        )}
        {config().CAREINADVANCE && (
          <div className={styles.field}>
            <input
              id="aftercareClients"
              type="checkbox"
              name="aftercareClients"
              className="is-checkradio is-large"
              onChange={() => onSelect('aftercareClients')}
              checked={aftercareClients}
            />
            <label htmlFor="aftercareClients">
              <p>AFTERCARE CLIENTS</p>
            </label>
          </div>
        )}
        {!config().BROKERAGEASB && !config().MYASBFINANCIAL && (
          <div className={styles.field}>
            <input
              id="prospects"
              type="checkbox"
              name="prospects"
              className="is-checkradio is-large"
              onChange={() => onSelect('prospects')}
              checked={prospects}
            />
            <label htmlFor="prospects">
              <p>PROSPECTS</p>
            </label>
          </div>
        )}
        <div className={styles.field}>
          <input
            id="clients"
            type="checkbox"
            name="clients"
            className="is-checkradio is-large"
            onChange={() => onSelect('clients')}
            checked={clients}
          />
          <label htmlFor="clients">
            <p>CLIENTS</p>
          </label>
        </div>
        {!config().MYASBFINANCIAL && (
          <div className={styles.field}>
            <input
              id="allContacts"
              type="checkbox"
              name="allContacts"
              className="is-checkradio is-large"
              onChange={() => onSelect('allContacts')}
              checked={allContacts}
            />
            <label htmlFor="allContacts">
              <p>ALL CONTACTS</p>
            </label>
          </div>
        )}
        {config().ASBAGENTMN && (
          <div className={styles.field}>
            <input
              id="spouseT65"
              type="checkbox"
              name="spouseT65"
              className="is-checkradio is-large"
              onChange={() => onSelect('spouseT65')}
              checked={spouseT65}
            />
            <label htmlFor="spouseT65">
              <p>T65</p>
            </label>
          </div>
        )}
        {!config().BROKERAGEASB && (
          <div className={styles.field}>
            <input
              id="clientsBirthdays"
              type="checkbox"
              name="clientsBirthdays"
              className="is-checkradio is-large"
              onChange={() => onSelect('clientsBirthdays')}
              checked={clientsBirthdays}
            />
            <label htmlFor="clientsBirthdays">
              <p>CLIENTS BIRTHDAYS</p>
            </label>
          </div>
        )}
        {!config().BROKERAGEASB && !config().MYASBFINANCIAL && (
          <div className={styles.field}>
            <input
              id="prospectsBirthdays"
              type="checkbox"
              name="prospectsBirthdays"
              className="is-checkradio is-large"
              onChange={() => onSelect('prospectsBirthdays')}
              checked={prospectsBirthdays}
            />
            <label htmlFor="prospectsBirthdays">
              <p>PROSPECTS BIRTHDAYS</p>
            </label>
          </div>
        )}
        {!config().BROKERAGEASB && (
          <div className={styles.field}>
            <input
              id="upcomingEvents"
              type="checkbox"
              name="upcomingEvents"
              className="is-checkradio is-large"
              onChange={() => onSelect('upcomingEvents')}
              checked={upcomingEvents}
            />
            <label htmlFor="upcomingEvents">
              <p>{currentUser.c_1 ? 'UPCOMING CALENDAR EVENTS' : 'UPCOMING APPOINTMENTS'}</p>
            </label>
          </div>
        )}
        {!currentUser.c_1 && !config().BROKERAGEASB && (
          <div className={styles.field}>
            <input
              id="upcomingTasks"
              type="checkbox"
              name="upcomingTasks"
              className="is-checkradio is-large"
              onChange={() => onSelect('upcomingTasks')}
              checked={upcomingTasks}
            />
            <label htmlFor="upcomingTasks">
              <p>UPCOMING TASKS</p>
            </label>
          </div>
        )}
        {!currentUser.c_1 && !config().BROKERAGEASB && (
          <div className={styles.field}>
            <input
              id="upcomingFollowUps"
              type="checkbox"
              name="upcomingFollowUps"
              className="is-checkradio is-large"
              onChange={() => onSelect('upcomingFollowUps')}
              checked={upcomingFollowUps}
            />
            <label htmlFor="upcomingFollowUps">
              <p>{config().CAREINADVANCE ? '' : 'UPCOMING '}FOLLOW UPS</p>
            </label>
          </div>
        )}
        {currentUser.c_1 && (
          <>
            <div className={styles.field}>
              <input
                id="recentlySavedContacts"
                type="checkbox"
                name="recentlySavedContacts"
                className="is-checkradio is-large"
                onChange={() => onSelect('recentlySavedContacts')}
                checked={recentlySavedContacts}
              />
              <label htmlFor="recentlySavedContacts">
                <p>RECENTLY SAVED</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="pastDueCalendarEvents"
                type="checkbox"
                name="pastDueCalendarEvents"
                className="is-checkradio is-large"
                onChange={() => onSelect('pastDueCalendarEvents')}
                checked={pastDueCalendarEvents}
              />
              <label htmlFor="pastDueCalendarEvents">
                <p>PAST DUE CALENDAR EVENTS</p>
              </label>
            </div>
          </>
        )}
        {config().MYASBFINANCIAL && (
          <>
            <div className={styles.field}>
              <input
                id="summary"
                type="checkbox"
                name="summary"
                className="is-checkradio is-large"
                onChange={() => onSelect('summary')}
                checked={summary}
              />
              <label htmlFor="summary">
                <p>FOCUS SUMMARY</p>
              </label>
            </div>
            <div className={styles.field}>
              <input
                id="pending"
                type="checkbox"
                name="pending"
                className="is-checkradio is-large"
                onChange={() => onSelect('pending')}
                checked={pending}
              />
              <label htmlFor="pending">
                <p>PENDING</p>
              </label>
            </div>
          </>
        )}
        {config().AEGLEADS && (
          <div className={styles.field}>
            <input
              id="partDClients"
              type="checkbox"
              name="partDClients"
              className="is-checkradio is-large"
              onChange={() => onSelect('partDClients')}
              checked={partDClients}
            />
            <label htmlFor="partDClients">
              <p>PART D CLIENTS</p>
            </label>
          </div>
        )}
      </div>

      <div className={styles.buttonsWrapper}>
        <button onClick={onReset} className="button is-large is-secondary">
          <p>Restore Default</p>
        </button>
        <button onClick={onSave} className="button is-large is-primary">
          <p>Ok</p>
        </button>
      </div>
    </div>
  );
};

export default withErrorBoundary(ActiveCards);
