import { useCallback, useState } from 'react';
import { publish as publishFormEvent } from 'pubsub-js';
import isEmail from 'validator/lib/isEmail';
import isDate from 'validator/lib/isDate';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isPostalCode from 'validator/lib/isPostalCode';
import { publish } from '@/utils/publish';
import { formatDate, formatPhone, capitalizeFirstLetter, camelToSnake } from '../../utils/formatters';
import { RESET_FORMS_EVENT } from '../../utils/constants';

const transformToErrors = initialFormData => {
  return Object.keys(initialFormData).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {});
};

const validationRules = {
  presence: value => (Array.isArray(value) ? value.length : !!value),
  email: value => isEmail(value),
  zipCode: value => isPostalCode(value, 'US'),
  phone: value => isMobilePhone(value, 'en-US'),
  dob: value =>
    isDate(value, { format: 'MM/DD/YYYY', delimiters: ['/', '-', ' '] }) &&
    parseInt(value.split('/')[2], 10) <= new Date().getFullYear()
};

const validationConfig = {
  sendEmailModal: {
    firstName: { required: true, validators: [validationRules.presence] },
    lastName: { required: true, validators: [validationRules.presence] },
    email: { required: true, validators: [validationRules.presence, validationRules.email] },
    phone: { required: false, validators: [validationRules.presence, validationRules.phone] },
    disclaimer: { required: true, validators: [validationRules.presence] },
    bestTime: { required: false, validators: [validationRules.presence] },
    comments: { required: false, validators: [validationRules.presence] },
    products: { required: true, validators: [validationRules.presence] },
    type: { required: true, validators: [validationRules.presence] }
  },
  getQuoteModal: {
    firstName: { required: true, validators: [validationRules.presence] },
    lastName: { required: true, validators: [validationRules.presence] },
    email: { required: true, validators: [validationRules.presence, validationRules.email] },
    phone: { required: true, validators: [validationRules.presence, validationRules.phone] },
    city: { required: true, validators: [validationRules.presence] },
    state: { required: true, validators: [validationRules.presence] },
    zipCode: { required: true, validators: [validationRules.presence, validationRules.zipCode] },
    dob: { required: true, validators: [validationRules.presence, validationRules.dob] },
    disclaimer: { required: true, validators: [validationRules.presence] },
    bestTime: { required: false, validators: [validationRules.presence] },
    comments: { required: false, validators: [validationRules.presence] },
    products: { required: true, validators: [validationRules.presence] },
    type: { required: true, validators: [validationRules.presence] }
  }
};

const formatConfig = {
  sendEmailModal: {
    firstName: value => capitalizeFirstLetter(value.trim()),
    lastName: value => capitalizeFirstLetter(value.trim()),
    email: value => value.toLowerCase().trim(),
    phone: value => formatPhone(value.trim()),
    bestTime: value => capitalizeFirstLetter(value.trim()),
    comments: value => value.trim(),
    products: value => value.map(product => capitalizeFirstLetter(product.replace(/_/g, ' '))).join(', '),
    disclaimer: value => value,
    type: value => value
  },
  getQuoteModal: {
    firstName: value => capitalizeFirstLetter(value.trim()),
    lastName: value => capitalizeFirstLetter(value.trim()),
    email: value => value.toLowerCase().trim(),
    phone: value => formatPhone(value.trim()),
    city: value =>
      value
        .trim()
        .split(' ')
        .map(word => capitalizeFirstLetter(word))
        .join(' '),
    state: value => value.trim(),
    zipCode: value => value.trim(),
    dob: value => formatDate(value.trim()),
    bestTime: value => capitalizeFirstLetter(value.trim()),
    comments: value => value.trim(),
    products: value => value.map(product => capitalizeFirstLetter(product.replace(/_/g, ' '))).join(', '),
    disclaimer: value => value,
    type: value => value
  }
};

export function useModalForm({ initialFormData, modalType, onSubmit, onSuccess }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const initialErrors = transformToErrors(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  const onInputChange = useCallback(e => {
    if (e.target.name === 'products') {
      if (e.target.checked) {
        setFormData(prevState => ({ ...prevState, products: [...prevState.products, e.target.value] }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          products: prevState.products.filter(product => product !== e.target.value)
        }));
      }
    } else if (e.target.name === 'disclaimer') {
      setFormData(prevState => ({ ...prevState, disclaimer: e.target.checked }));
    } else {
      setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    setErrors(prevState => ({ ...prevState, [e.target.name]: false }));
  }, []);

  const resetForm = useCallback(() => {
    setFormData(initialFormData);
    setErrors(initialErrors);
  }, []);

  const isFormValid = useCallback(formData => {
    const newErrors = {};
    const config = validationConfig[modalType] || {};

    Object.keys(config).forEach(key => {
      const { required, validators } = config[key];
      const value = formData[key];

      if (required) {
        const isEmpty = validators.includes(validationRules.presence) && !validators[0](value);
        const isBadFormat = validators.slice(1).some(validator => !validator(value));
        newErrors[key] = isEmpty || isBadFormat;
      } else {
        if (value) {
          newErrors[key] = validators.some(validator => !validator(value));
        } else {
          newErrors[key] = false;
        }
      }
    });

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (hasError) {
      return false;
    }
    return true;
  }, []);

  const prepareForm = useCallback(validatedData => {
    const config = formatConfig[modalType] || {};
    const formattedData = {};

    Object.keys(validatedData).forEach(key => {
      if (config[key]) {
        if (key !== 'disclaimer') {
          const snakeKey = camelToSnake(key);
          formattedData[snakeKey] = config[key](validatedData[key]);
        } else {
          formattedData['text_permission'] = true;
          formattedData['call_permission'] = true;
          formattedData['email_permission'] = true;
          delete formattedData['disclaimer'];
        }
      } else {
        const snakeKey = camelToSnake(key);
        formattedData[snakeKey] = validatedData[key];
      }
    });

    return formattedData;
  }, []);

  const onFormSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isFormValid(formData)) {
        const body = prepareForm(formData);
        try {
          setIsSubmitting(true);
          await onSubmit(body);
          resetForm();
          publishFormEvent(RESET_FORMS_EVENT);
          setIsSubmitting(false);
          publish('success', 'Your request was successfully sent.');
          onSuccess();
        } catch (ex) {
          console.error('Error:', ex);
          publish('error', ex);
          setIsSubmitting(false);
        }
      }
    },
    [isFormValid, formData, prepareForm, resetForm, onSuccess]
  );

  return { isSubmitting, formData, errors, onInputChange, onFormSubmit, resetForm };
}
