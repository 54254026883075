import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';

import styles from './Footer.module.scss';
import { config } from '@/config';
import { linkWithHttps } from '@/utils/helpers';

const ArisDisclaimer = () => {
  return (
    <p className={styles.footerText}>
      © 2022 American Republic Insurance Services, LLC. ® &quot;American Republic Insurance Services&quot;, &quot;Be
      Informed. Be Smart. Be Secure.&quot;, and the eagle logo are registered trademarks of American Republic Insurance
      Services, LLC. Not connected with or endorsed by the United States government or the federal Medicare program. In
      April 2022, AM Best affirmed the Financial Strength Ratings of A (Excellent) and the stable outlooks for American
      Enterprise Group, Inc.&apos;s six insurance company subsidiaries, which operate under the brands of American
      Republic®, Medico®, and Great Western Insurance Company (GWIC®). For the latest Best&apos;s Credit Rating, visit{' '}
      <a href="https://web.ambest.com/home" target="_blank" rel="noreferrer">
        ambest.com
      </a>
      .
    </p>
  );
};

const Footer = ({ info }) => {
  let {
    profile: { facebook, instagram, twitter, linkedin, youtube, flickr, plans_offered, carriers } = {},
    corporation: { color_1, color_2 } = {},
    aris,
    webpageSmsDisclaimerTitle
  } = info;
  const showAris = () => {
    return config().AEGLEADS && aris;
  };

  facebook = linkWithHttps(facebook);
  instagram = linkWithHttps(instagram);
  twitter = linkWithHttps(twitter);
  linkedin = linkWithHttps(linkedin);
  youtube = linkWithHttps(youtube);
  flickr = linkWithHttps(flickr);

  return (
    <div style={{ background: `${color_1}` }} className={styles.footer}>
      <div className={classNames(styles.socialNetworksWrapper)}>
        <div className={classNames(styles.socialNetworks, tinycolor(color_1).isLight() && styles.light)}>
          {facebook && (
            <a href={facebook} target="_blank" rel="noreferrer">
              <img className="social-icon" src="/static/icons/webpage/facebook-abs.png" alt="facebook" />
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank" rel="noreferrer">
              <img src="/static/icons/webpage/twitter-abs.png" alt="twitter" />
            </a>
          )}

          {linkedin && (
            <a href={linkedin} target="_blank" rel="noreferrer">
              <img src="/static/icons/webpage/linkedin-abs.png" alt="linkedin" />
            </a>
          )}
          {instagram && (
            <a href={instagram} target="_blank" rel="noreferrer">
              <img src="/static/icons/webpage/instagram-abs.png" alt="instagram" />
            </a>
          )}
          {youtube && (
            <a href={youtube} target="_blank" rel="noreferrer">
              <img src="/static/icons/webpage/youtube.png" alt="youtube" />
            </a>
          )}
          {flickr && (
            <a href={flickr} target="_blank" rel="noreferrer">
              <img src="/static/icons/webpage/flickr.png" alt="flickr" />
            </a>
          )}
        </div>
      </div>
      <div className={tinycolor(color_2).isLight() ? styles.black : styles.white}>
        {showAris() ? (
          <ArisDisclaimer />
        ) : (
          <p className={styles.footerText}>
            {config().CAREINADVANCE ? (
              <>
                *We do not offer every plan available in your area. Any information provided is limited to those plans
                we do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to get information on all of
                your options. Not affiliated or endorsed by any government agency. Your information will be provided to
                a licensed insurance agent. You may be contacted by a licensed insurance agent.
              </>
            ) : (
              <>
                *We do not offer every plan available in your area. Currently we represent {carriers} organizations
                which offer {plans_offered} products in your area. Please contact Medicare.gov, 1-800-MEDICARE, or your
                local State Health Insurance Program (SHIP) to get information on all of your options.
              </>
            )}
          </p>
        )}
        <div className={classNames(tinycolor(color_2).isLight() ? styles.black : styles.white, styles.footerText)}>
          <div className={styles.copyright}>
            <p>© Copyright {new Date().getFullYear()} All rights reserved</p>
          </div>
          {config().ASB_SYSTEM && webpageSmsDisclaimerTitle && (
            <div className={styles.smsDisclaimer}>
              <a
                href="static/sms/ASB SMS Disclaimer - Sept 2023.pdf"
                className={tinycolor(color_2).isLight() ? styles.black : styles.white}
              >
                {webpageSmsDisclaimerTitle}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(Footer);
