import { memo } from 'react';
import styles from './Footer.module.scss';

export const Footer = memo(({ children }) => {
  return (
    <footer className={styles.footerContainer}>
      <h3 className={styles.footerHeader}>Let's Get You Covered</h3>
      <h4 className={styles.footerSubHeader}>
        I'll work with you to find a plan that suits your need - and your budget.
      </h4>
      {children}
    </footer>
  );
});
